<template>
  <v-dialog
    v-model="showModal"
    max-width="400"
  >
    <v-card
      class="mx-auto"
    >
      <v-toolbar
        color="#37474F"
        dark
      >
        <v-toolbar-title>
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <p class="message-style">
          {{ message }}
        </p>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="showModal = false"
          >
            {{ cancelButton }}
          </v-btn>
          <v-btn
            color="red darken-1"
            text
            @click="$emit('confirmed')"
          >
            {{ confirmButton }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
  export default {
    name: 'DeleteDialog',
    props: {
      title: {
        type: String,
        default: 'Delete',
      },
      message: {
        type: String,
        default: 'This action will permanently remove the item.',
      },
      cancelButton: {
        type: String,
        default: 'Cancel',
      },
      confirmButton: {
        type: String,
        default: 'Delete',
      },
    },
    data: () => ({
      showModal: true,
    }),
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
  };
</script>
<style scoped>
.message-style {
  font-weight: 500;
  color: #455A64;
}
</style>
