import store from 'src/store/index';

export default class ModuleHelper {
    isModuleExist (moduleType) {
        const modules = store.getters['orgsettings/getOrgSettings'];
        let module = {};
        for (var i = 0; i < modules.length; i++) {
            if (modules[i].name === moduleType) {
                module = modules[i];
                break;
            }
        }
        if (Object.keys(module).length > 0 && module.settings.enabled === true) {
            return true;
        }

        return false;
    }

    isSubModuleExist (moduleType, submoduleType) {
        const modules = store.getters['orgsettings/getOrgSettings'];
        let module = {};
        for (var i = 0; i < modules.length; i++) {
            if (modules[i].name === moduleType) {
                module = modules[i];
                break;
            }
        }
        if ((Object.keys(module).length > 0 && module.settings.enabled === true) &&
            (submoduleType in module.settings && module.settings[submoduleType].enabled === true)) {
            return true;
        }

        return false;
    }

    isSubModuleExistUnderSubModule (moduleType, subModuleType, subModuleTypeUnderSubModule) {
        const modules = store.getters['orgsettings/getOrgSettings'];
        let module = {};
        for (var i = 0; i < modules.length; i++) {
            if (modules[i].name === moduleType) {
                module = modules[i];
                break;
            }
        }
        if ((Object.keys(module).length > 0 && module.settings.enabled === true) &&
            (subModuleType in module.settings && module.settings[subModuleType].enabled === true) && (subModuleTypeUnderSubModule in module.settings[subModuleType] && module.settings[subModuleType][subModuleTypeUnderSubModule].enabled === true)) {
            return true;
        }

        return false;
    }
};
